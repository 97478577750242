/* eslint-disable no-param-reassign */
import produce from 'immer';
import _ from 'lodash';
import arrayMove from 'array-move';
import {
  GET_BLOCKS,
  ADD_BLOCK,
  ADD_IMAGE,
  ADD_OPTION_IMAGE,
  ADD_IMAGE_ANSWER_OPTION,
  ADD_MC_OPTION,
  ADD_EXPRESSION,
  ACTIVE_BLOCK,
  DELETE_BLOCK,
  DELETE_OPTION,
  DELETE_EXPRESSION,
  DELETE_IMAGE_ANSWER_OPTION,
  DUPLICATE_BLOCK,
  DELETE_SHORT_TEXT_OPTION,
  EDIT_FEEDBACK,
  EDIT_GRAPH_LATEX,
  EDIT_INITIAL_RATING,
  EDIT_CORRECT_RATING,
  EDIT_SHORT_TEXT_OPTION,
  ADD_SHORT_TEXT_OPTION,
  EDIT_NUMBER_OF_STARS,
  EDIT_HINT,
  EDIT_AUDIO_SOURCE,
  EDIT_MIN_SLIDER_VALUE,
  EDIT_MAX_SLIDER_VALUE,
  EDIT_CORRECT_SLIDER_VALUE,
  EDIT_DEFAULT_SLIDER_VALUE,
  EDIT_SLIDER_STEPS,
  EDIT_DROPDOWN_INSTRUCTION,
  EDIT_OPTION_TEXT,
  EDIT_QUESTION_TEXT,
  EDIT_QUESTION_DESCRIPTION,
  MATHS_PREVIEW,
  MOVE_BLOCK,
  NEW_SESSION,
  SELECT_CORRECT_OPTION,
  SELECT_MULTI_CORRECT_OPTION,
  SELECT_CORRECT_IMAGE_OPTION,
  SHOW_PICTURE_IMAGE,
  SHOW_LATEX_GRAPH,
  SAVE_NEW_SESSION,
  SAVE_UPDATED_SESSION,
  SET_NUMERIC_ONLY,
  TOGGLE_QUOTED_TEXT,
  RATINGS_ICON,
  REMOVE_IMAGE,
  REFRESH_GRAPH,
  REMOVE_OPTION_IMAGE
} from 'src/actions/sessionActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoaded: false,
  isSaved: true,
  isNewSession: false,
  blocks: [],
  activeBlock: {},
  activeBlockIndex: null
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_BLOCK: {
      const { block, index } = action.payload;

      return produce(state, draft => {
        draft.activeBlock = block;
        draft.activeBlockIndex = index;
      });
    }

    case ADD_BLOCK: {
      return produce(state, draft => {
        draft.blocks.push(action.payload);
        draft.isSaved = false;
      });
    }

    case ADD_IMAGE_ANSWER_OPTION: {
      const index = action.payload;
      return produce(state, draft => {
        draft.blocks[index].imageOptions.push({ isCorrect: false });
        draft.activeBlock.imageOptions.push({ isCorrect: false });
        draft.isSaved = false;
      });
    }

    case ADD_SHORT_TEXT_OPTION: {
      return produce(state, draft => {
        draft.blocks[action.payload].shortTextCorrectOptions.push(
          'Edit New Answer Variable'
        );
        draft.activeBlock.shortTextCorrectOptions.push(
          'Edit New Answer Variable'
        );
        draft.isSaved = false;
      });
    }

    case DELETE_SHORT_TEXT_OPTION: {
      const { blockIndex, optionIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].shortTextCorrectOptions.splice(optionIndex, 1);
        draft.activeBlock.shortTextCorrectOptions.splice(optionIndex, 1);
        draft.isSaved = false;
      });
    }

    case DELETE_BLOCK: {
      return produce(state, draft => {
        draft.blocks.splice(action.payload, 1);
        draft.activeBlock = {};
        draft.isSaved = false;
      });
    }

    case DUPLICATE_BLOCK: {
      const { blockIndex, newBlocks } = action.payload;
      return produce(state, draft => {
        draft.activeBlock = newBlocks[blockIndex];
        draft.blocks = newBlocks;
        draft.activeBlockIndex = blockIndex;
        draft.isSaved = false;
      });
    }

    case EDIT_QUESTION_DESCRIPTION: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].description = text;
        draft.activeBlock.description = text;
        draft.isSaved = false;
      });
    }

    case EDIT_SHORT_TEXT_OPTION: {
      const { blockIndex, text, OptionIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].shortTextCorrectOptions[OptionIndex] = text;
        draft.activeBlock.shortTextCorrectOptions[OptionIndex] = text;
        draft.isSaved = false;
      });
    }

    case EDIT_INITIAL_RATING: {
      const { blockIndex, value } = action.payload;

      return produce(state, draft => {
        if (!value) {
          draft.blocks[blockIndex].rating.rating = 0;
          draft.activeBlock.rating.rating = 0;
        } else {
          draft.blocks[blockIndex].rating.rating = value;
          draft.activeBlock.rating.rating = value;
        }
        draft.isSaved = false;
      });
    }

    case EDIT_CORRECT_RATING: {
      const { blockIndex, value } = action.payload;

      return produce(state, draft => {
        if (!value) {
          draft.blocks[blockIndex].rating.correctRating = 0;
          draft.activeBlock.rating.correctRating = 0;
        } else {
          draft.blocks[blockIndex].rating.correctRating = value;
          draft.activeBlock.rating.correctRating = value;
        }
        draft.isSaved = false;
      });
    }

    case EDIT_NUMBER_OF_STARS: {
      const { blockIndex, value } = action.payload;

      return produce(state, draft => {
        if (!value) {
          draft.blocks[blockIndex].rating.numberOfStars = 0;
          draft.activeBlock.rating.numberOfStars = 0;
        } else {
          draft.blocks[blockIndex].rating.numberOfStars = value;
          draft.activeBlock.rating.numberOfStars = value;
        }
        draft.isSaved = false;
      });
    }

    case EDIT_OPTION_TEXT: {
      const { blockIndex, optionIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].answerOptions[optionIndex].answerText = text;
        draft.activeBlock.answerOptions[optionIndex].answerText = text;
        draft.isSaved = false;
      });
    }

    case EDIT_FEEDBACK: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].feedback = text;
        draft.activeBlock.feedback = text;
        draft.isSaved = false;
      });
    }

    case EDIT_GRAPH_LATEX: {
      const { blockIndex, optionIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].graphLatex[optionIndex] = text;
        draft.activeBlock.graphLatex[optionIndex] = text;
        draft.isSaved = false;
      });
    }

    case REFRESH_GRAPH: {
      const { blockIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].refreshingGraph = !draft.blocks[blockIndex]
          .refreshingGraph;
        draft.activeBlock.refreshingGraph = !draft.activeBlock.refreshingGraph;
        draft.isSaved = false;
      });
    }

    case EDIT_DROPDOWN_INSTRUCTION: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].dropdownInstruction = text;
        draft.activeBlock.dropdownInstruction = text;
        draft.isSaved = false;
      });
    }

    case EDIT_HINT: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].hint = text;
        draft.activeBlock.hint = text;
        draft.isSaved = false;
      });
    }

    case EDIT_AUDIO_SOURCE: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].audioPlayerSRC = text;
        draft.activeBlock.audioPlayerSRC = text;
        draft.isSaved = false;
      });
    }

    case EDIT_MIN_SLIDER_VALUE: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].minSliderValue = text;
        draft.activeBlock.minSliderValue = text;
        draft.isSaved = false;
      });
    }

    case EDIT_MAX_SLIDER_VALUE: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].maxSliderValue = text;
        draft.activeBlock.maxSliderValue = text;
        draft.isSaved = false;
      });
    }

    case EDIT_DEFAULT_SLIDER_VALUE: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].sliderValue = text;
        draft.activeBlock.sliderValue = text;
        draft.isSaved = false;
      });
    }

    case EDIT_CORRECT_SLIDER_VALUE: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].sliderCorrectValue = text;
        draft.activeBlock.sliderCorrectValue = text;
        draft.isSaved = false;
      });
    }

    case EDIT_SLIDER_STEPS: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].sliderSteps = text;
        draft.activeBlock.sliderSteps = text;
        draft.isSaved = false;
      });
    }

    case EDIT_QUESTION_TEXT: {
      const { blockIndex, text } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].questionText = text;
        draft.activeBlock.questionText = text;
        draft.isSaved = false;
      });
    }

    case GET_BLOCKS: {
      const { blocks } = action.payload;

      return produce(state, draft => {
        draft.blocks = blocks;
        draft.isLoaded = true;
        draft.isNewSession = false;
        draft.activeBlock = {};
        draft.activeBlockIndex = null;
      });
    }

    case SELECT_CORRECT_OPTION: {
      const { blockIndex, optionIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].answerOptions[optionIndex].isCorrect = true;
        draft.blocks[blockIndex].answerOptions.forEach((option, index) => {
          if (index !== optionIndex) {
            option.isCorrect = false;
          }
        });

        draft.activeBlock.answerOptions[optionIndex].isCorrect = true;
        draft.activeBlock.answerOptions.forEach((option, index) => {
          if (index !== optionIndex) {
            option.isCorrect = false;
          }
        });

        draft.isSaved = false;
      });
    }

    case SELECT_MULTI_CORRECT_OPTION: {
      const { blockIndex, optionIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].answerOptions[optionIndex].isCorrect = !draft
          .blocks[blockIndex].answerOptions[optionIndex].isCorrect;
        draft.activeBlock.answerOptions[optionIndex].isCorrect = !draft
          .activeBlock.answerOptions[optionIndex].isCorrect;

        draft.isSaved = false;
      });
    }

    case SELECT_CORRECT_IMAGE_OPTION: {
      const { blockIndex, optionIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].imageOptions[optionIndex].isCorrect = true;
        draft.blocks[blockIndex].imageOptions.forEach((option, index) => {
          if (index !== optionIndex) {
            option.isCorrect = false;
          }
        });

        draft.activeBlock.imageOptions[optionIndex].isCorrect = true;
        draft.activeBlock.imageOptions.forEach((option, index) => {
          if (index !== optionIndex) {
            option.isCorrect = false;
          }
        });

        draft.isSaved = false;
      });
    }

    case MATHS_PREVIEW: {
      const { blockIndex, blockProperty, optionIndex } = action.payload;

      switch (blockProperty) {
        case 'questionText': {
          return produce(state, draft => {
            draft.blocks[blockIndex].useMaths.questionText = !draft.blocks[
              blockIndex
            ].useMaths.questionText;
            draft.activeBlock.useMaths.questionText = !draft.activeBlock
              .useMaths.questionText;
            draft.blocks[blockIndex].quotedQuestionText = false;
            draft.activeBlock.quotedQuestionText = false;
            draft.isSaved = false;
          });
        }

        case 'description': {
          return produce(state, draft => {
            draft.blocks[blockIndex].useMaths.description = !draft.blocks[
              blockIndex
            ].useMaths.description;
            draft.activeBlock.useMaths.description = !draft.activeBlock.useMaths
              .description;
            draft.isSaved = false;
          });
        }

        case 'feedback': {
          return produce(state, draft => {
            draft.blocks[blockIndex].useMaths.feedback = !draft.blocks[
              blockIndex
            ].useMaths.feedback;
            draft.activeBlock.useMaths.feedback = !draft.activeBlock.useMaths
              .feedback;
            draft.isSaved = false;
          });
        }

        case 'hint': {
          return produce(state, draft => {
            draft.blocks[blockIndex].useMaths.hint = !draft.blocks[blockIndex]
              .useMaths.hint;
            draft.activeBlock.useMaths.hint = !draft.activeBlock.useMaths.hint;
            draft.isSaved = false;
          });
        }

        case 'option': {
          return produce(state, draft => {
            draft.blocks[blockIndex].answerOptions[
              optionIndex
            ].useMaths = !draft.blocks[blockIndex].answerOptions[optionIndex]
              .useMaths;
            draft.activeBlock.answerOptions[optionIndex].useMaths = !draft
              .activeBlock.answerOptions[optionIndex].useMaths;
            draft.isSaved = false;
          });
        }
      }
    }

    case TOGGLE_QUOTED_TEXT: {
      const { blockIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].quotedQuestionText = !draft.blocks[blockIndex]
          .quotedQuestionText;
        draft.activeBlock.quotedQuestionText = !draft.activeBlock
          .quotedQuestionText;
        draft.blocks[blockIndex].useMaths.questionText = false;
        draft.activeBlock.useMaths.questionText = false;
        draft.isSaved = false;
      });
    }

    case SHOW_PICTURE_IMAGE: {
      const { blockIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].isGraphOverImage = false;
        draft.activeBlock.isGraphOverImage = false;
        draft.isSaved = false;
      });
    }

    case SHOW_LATEX_GRAPH: {
      const { blockIndex } = action.payload;

      return produce(state, draft => {
        draft.blocks[blockIndex].isGraphOverImage = true;
        draft.activeBlock.isGraphOverImage = true;
        draft.isSaved = false;
      });
    }

    case SET_NUMERIC_ONLY: {
      const { blockIndex } = action.payload;
      return produce(state, draft => {
        draft.blocks[blockIndex].numericOnly = !draft.blocks[blockIndex]
          .numericOnly;
        draft.activeBlock.numericOnly = !draft.activeBlock.numericOnly;
        draft.isSaved = false;
      });
    }

    case MOVE_BLOCK: {
      const { fromIndex, toIndex } = action.payload;
      return produce(state, draft => {
        draft.blocks = arrayMove(draft.blocks, fromIndex, toIndex);
        draft.activeBlock = draft.blocks[toIndex];
        draft.activeBlockIndex = toIndex;
        draft.isSaved = false;
      });
    }

    case NEW_SESSION: {
      return produce(state, draft => {
        draft.isLoaded = true;
        draft.isNewSession = true;
        draft.blocks = [];
        draft.activeBlock = {};
        draft.activeBlockIndex = null;
      });
    }

    case SAVE_NEW_SESSION: {
      const { session } = action.payload;
      return produce(state, draft => {
        draft.blocks = session.blocks;
        draft.isSaved = true;
        draft.isNewSession = false;
      });
    }

    case SAVE_UPDATED_SESSION: {
      return produce(state, draft => {
        draft.isSaved = true;
      });
    }

    case ADD_IMAGE: {
      try {
        const { blockIndex, file } = action.payload;
        return produce(state, draft => {
          draft.blocks[blockIndex].imageFile = file;
          draft.activeBlock.imageFile = file;
          draft.isSaved = false;
        });
      } catch (e) {
        console.log(e);
      }
    }

    case ADD_OPTION_IMAGE: {
      try {
        const { blockIndex, file, optionIndex } = action.payload;
        return produce(state, draft => {
          draft.blocks[blockIndex].imageOptions[optionIndex].imageSrc = file;
          draft.activeBlock.imageOptions[optionIndex].imageSrc = file;
          draft.isSaved = false;
        });
      } catch (e) {
        console.log(e);
      }
    }

    case RATINGS_ICON: {
      const { blockIndex, icon } = action.payload;
      console.log('RATINGS_ICON', blockIndex);
      return produce(state, draft => {
        draft.blocks[blockIndex].ratingsIcon.label = icon.label;
        draft.blocks[blockIndex].ratingsIcon.iconPath = icon.iconPath;
        draft.blocks[blockIndex].ratingsIcon.dimension = icon.dimension;
        draft.blocks[blockIndex].ratingsIcon.spacing = icon.spacing;
        draft.blocks[blockIndex].ratingsIcon.viewBox = icon.viewBox;
        draft.activeBlock.ratingsIcon.iconPath = icon.iconPath;
        draft.activeBlock.ratingsIcon.label = icon.label;
        draft.activeBlock.ratingsIcon.dimension = icon.dimension;
        draft.activeBlock.ratingsIcon.spacing = icon.spacing;
        draft.activeBlock.ratingsIcon.viewBox = icon.viewBox;
        draft.isSaved = false;
      });
    }

    case REMOVE_IMAGE: {
      const { blockIndex } = action.payload;
      console.log('REMOVE_IMAGE', blockIndex);
      return produce(state, draft => {
        draft.blocks[blockIndex].imageFile = null;
        draft.activeBlock.imageFile = null;
        draft.isSaved = false;
      });
    }

    case DELETE_IMAGE_ANSWER_OPTION: {
      const { blockIndex, optionIndex } = action.payload;
      return produce(state, draft => {
        draft.blocks[blockIndex].imageOptions.splice(optionIndex, 1);
        draft.activeBlock.imageOptions.splice(optionIndex, 1);
        draft.isSaved = false;
      });
    }

    case DELETE_OPTION: {
      const { blockIndex, optionIndex } = action.payload;

      return produce(state, draft => {
        draft.isSaved = false;
        draft.blocks[blockIndex].answerOptions.splice(optionIndex, 1);
        draft.activeBlock.answerOptions.splice(optionIndex, 1);
        draft.blocks[blockIndex].answerOptions.forEach((option, index) => {
          if (index === 0) {
            option.index = 'A';
          }
          if (index === 1) {
            option.index = 'B';
          }
          if (index === 2) {
            option.index = 'C';
          }
          if (index === 3) {
            option.index = 'D';
          }
          if (index === 4) {
            option.index = 'E';
          }
          if (index === 5) {
            option.index = 'F';
          }
          if (index === 6) {
            option.index = 'G';
          }
          if (index === 7) {
            option.index = 'H';
          }
          if (index === 8) {
            option.index = 'I';
          }
          if (index === 9) {
            option.index = 'J';
          }
        });
        draft.activeBlock.answerOptions.forEach((option, index) => {
          if (index === 0) {
            option.index = 'A';
          }
          if (index === 1) {
            option.index = 'B';
          }
          if (index === 2) {
            option.index = 'C';
          }
          if (index === 3) {
            option.index = 'D';
          }
          if (index === 4) {
            option.index = 'E';
          }
          if (index === 5) {
            option.index = 'F';
          }
          if (index === 6) {
            option.index = 'G';
          }
          if (index === 7) {
            option.index = 'H';
          }
          if (index === 8) {
            option.index = 'I';
          }
          if (index === 9) {
            option.index = 'J';
          }
        });
      });
    }

    case ADD_MC_OPTION: {
      return produce(state, draft => {
        draft.isSaved = false;
        const arrayLength = draft.blocks[action.payload].answerOptions.length;
        let index = null;
        if (arrayLength === 0) {
          index = 'A';
        }
        if (arrayLength === 1) {
          index = 'B';
        }
        if (arrayLength === 2) {
          index = 'C';
        }
        if (arrayLength === 3) {
          index = 'D';
        }
        if (arrayLength === 4) {
          index = 'E';
        }
        if (arrayLength === 5) {
          index = 'F';
        }
        if (arrayLength === 6) {
          index = 'G';
        }
        if (arrayLength === 7) {
          index = 'H';
        }
        if (arrayLength === 8) {
          index = 'I';
        }
        if (arrayLength === 9) {
          index = 'J';
        }
        const activeBlockLength = draft.activeBlock.answerOptions.length;
        let activeIndex = null;
        if (activeBlockLength === 0) {
          activeIndex = 'A';
        }
        if (activeBlockLength === 1) {
          activeIndex = 'B';
        }
        if (activeBlockLength === 2) {
          activeIndex = 'C';
        }
        if (activeBlockLength === 3) {
          activeIndex = 'D';
        }
        if (activeBlockLength === 4) {
          activeIndex = 'E';
        }
        if (activeBlockLength === 5) {
          activeIndex = 'F';
        }
        if (activeBlockLength === 6) {
          activeIndex = 'G';
        }
        if (activeBlockLength === 7) {
          activeIndex = 'H';
        }
        if (activeBlockLength === 8) {
          activeIndex = 'I';
        }
        if (activeBlockLength === 9) {
          activeIndex = 'J';
        }
        draft.blocks[action.payload].answerOptions.push({
          answerText: 'Edit this option',
          isCorrect: false,
          index
        });
        draft.activeBlock.answerOptions.push({
          answerText: 'Edit this option',
          isCorrect: false,
          index: activeIndex
        });
      });
    }

    case ADD_EXPRESSION: {
      return produce(state, draft => {
        draft.blocks[action.payload].graphLatex.push('');
        draft.activeBlock.graphLatex.push('');
        draft.isSaved = false;
      });
    }

    case DELETE_EXPRESSION: {
      const { blockIndex, optionIndex } = action.payload;
      return produce(state, draft => {
        draft.blocks[blockIndex].graphLatex.splice(optionIndex, 1);
        draft.activeBlock.graphLatex.splice(optionIndex, 1);
        draft.isSaved = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
