import { CurriculumApi } from 'src/services/curriculumService';

export const SET_SUBJECT_OPTIONS = '@lessonRequest/set-subject-options';

export function setSubjectOptions() {
  return async dispatch => {
    try {
      let subjectOptions = await CurriculumApi.getSubjects();
      subjectOptions.sort((a, b) => {
        const order = ["Igbo", "Yoruba"];
        const aIndex = order.indexOf(a.value);
        const bIndex = order.indexOf(b.value);
      
        if (aIndex === -1 && bIndex === -1) return 0; 
        if (aIndex === -1) return 1; 
        if (bIndex === -1) return -1; 
      
        return aIndex - bIndex;
      });
      dispatch({
        type: SET_SUBJECT_OPTIONS,
        payload: subjectOptions
      });
    } catch (error) {
      throw error;
    }
  };
}
