import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import sessionReducer from './sessionReducer';
import lessonRequestReducer from './lessonRequestReducer';
import customerReducer from './customerReducer';
import subjectReducer from './subjectReducer';
import invoiceReducer from './invoiceReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  form: formReducer,
  session: sessionReducer,
  lessonRequest: lessonRequestReducer,
  customer: customerReducer,
  subjects: subjectReducer,
  invoices: invoiceReducer
});

export default rootReducer;
