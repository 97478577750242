/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
  // UNICORN: 'UNICORN'
};

// export const USER_ROLES = {
//   SUPER_ADMIN: 'super-admin',
//   ADMIN: 'admin',
//   CONTENT_EDITOR: 'content-editor'
// };
export const REGIONS = ['UK', 'US', 'NG'];
export const USER_ROLES = [
  {
    id: '',
    name: ''
  },
  {
    id: 'admin',
    name: 'Admin'
  },
  {
    id: 'content-creator',
    name: 'Content creator'
  }
];

export const PUBLISH_STATUS = [
  {
    id: 'No Content',
    name: 'No Content'
  },
  {
    id: 'Draft',
    name: 'Draft'
  },
  {
    id: 'Review',
    name: 'Review'
  }
];

export const CONTENT_STATUS = [
  {
    id: 'Select',
    name: 'Select'
  },
  {
    id: 'Active',
    name: 'Active'
  },
  {
    id: 'Inactive',
    name: 'Inactive'
  }
];

export const ADMIN_EDITOR_STATUS = [
  {
    id: 'Select',
    name: 'Select'
  },
  {
    id: 'No Content',
    name: 'No Content'
  },
  {
    id: 'Draft',
    name: 'Draft'
  },
  {
    id: 'Review',
    name: 'Review'
  },
  {
    id: 'Published',
    name: 'Published'
  }
];

export const PRICE_CURRENCY = [
  {
    id: '1',
    name: 'Select Currency'
  },
  {
    id: 'USD',
    name: 'USD'
  },
  {
    id: 'NGN',
    name: 'NGN'
  },
  {
    id: 'GBP',
    name: 'GBP'
  }
];
