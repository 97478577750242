/* eslint-disable no-param-reassign */
import Axios from 'axios';
import produce from 'immer';
import {
  SET_SELECTED_CHILD_OR_DEPENDANT,
  SET_TUTORS
} from 'src/actions/customerActions';

const initialState = {
  selectedChildOrDependant: null,
  tutors: []
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CHILD_OR_DEPENDANT: {
      const selectedChildOrDependant = action.payload;
      return produce(state, draft => {
        draft.selectedChildOrDependant = selectedChildOrDependant;
      });
    }
    case SET_TUTORS:
      return produce(state, draft => {
        draft.tutors = action.payload;
      });

    default: {
      return state;
    }
  }
};

export default customerReducer;
