import axios from 'axios';
import { apiV2 } from 'src/config';

export const CurriculumApi = {
  async getRegions() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_V2_SERVER_URL}/admin/region`
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async createRegion(body) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V2_SERVER_URL}/admin/region`,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async updateCurriculum(body) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_V2_SERVER_URL}/curriculum/curriculum`,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async createCurriculum(body) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V2_SERVER_URL}/curriculum/curriculum`,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async getCurriculum(regionId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_V2_SERVER_URL}/curriculum/curriculum`,
        {
          params: {
            regionId
          }
        }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async getCurriculumSubdivision(body) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_V2_SERVER_URL}/curriculum/curriculum/subdivision`,
        {
          params: body
        }
      );

      return response.data;
    } catch (error) {
      return false;
    }
  },
  async updateCurriculumSubdivision(body) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_V2_SERVER_URL}/curriculum/curriculum/subdivision`,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async createCurriculumSubdivision(body) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_V2_SERVER_URL}/curriculum/curriculum/subdivision`,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async appendToCurriculum(body) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/student-attach-curriculum`,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async getSubjects(body) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/subjects-options`,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async getLessonProgress(studentId) {
    try {
      const response = await apiV2.get(`lessons/lesson-progress/${studentId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};
