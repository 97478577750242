import Axios from 'axios';

// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;

const apiV1 = Axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`
});
const apiV2 = Axios.create({
  baseURL: `${process.env.REACT_APP_V2_SERVER_URL}/`
});
apiV1.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let errorMessage = 'An error occurred';
    if (error.response) {
      errorMessage = error.response.data.message || error.message;
    } else if (error.request) {
      errorMessage = 'Network error';
    } else {
      errorMessage = error.message;
    }

    return Promise.reject(errorMessage);
  }
);
apiV2.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let errorMessage = 'An error occurred';
    if (error.response) {
      console.dir(error);
      console.log(error.response.status);
      console.log(error.response.headers);
      errorMessage =
        error.response.data.message ||
        error.response.data.error ||
        error.message ||
        errorMessage;
    } else if (error.request) {
      errorMessage = 'Network error';
    } else {
      errorMessage = error.message;
    }

    return Promise.reject(errorMessage);
  }
);

export { apiV1, apiV2 };
