/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_SELECTED_TUTOR,
  SET_USER_LESSON_REQUEST,
  SET_SELECTED_LESSON_SCHEDULE,
  SET_IS_RESCHEDULE,
  SET_SELECTED_CONFIRMED_LESSON
} from 'src/actions/lessonRequestActions';

const initialState = {
  tutor: null,
  userLessonRequest: null,
  selectedLessonSchedule: null,
  isReschedule: false,
  selectedConfirmedLesson: null
};

const lessonRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_TUTOR: {
      const tutor = action.payload;
      return produce(state, draft => {
        draft.tutor = tutor;
      });
    }
    case SET_USER_LESSON_REQUEST: {
      const userLessonRequest = action.payload;
      return produce(state, draft => {
        draft.userLessonRequest = userLessonRequest;
      });
    }
    case SET_SELECTED_LESSON_SCHEDULE: {
      const selectedLessonSchedule = action.payload;
      return produce(state, draft => {
        draft.selectedLessonSchedule = selectedLessonSchedule;
      });
    }
    case SET_IS_RESCHEDULE: {
      const isReschedule = action.payload;
      return produce(state, draft => {
        draft.isReschedule = isReschedule;
      });
    }
    case SET_SELECTED_CONFIRMED_LESSON: {
      const selectedConfirmedLesson = action.payload;
      return produce(state, draft => {
        draft.selectedConfirmedLesson = selectedConfirmedLesson;
      });
    }

    default: {
      return state;
    }
  }
};

export default lessonRequestReducer;
