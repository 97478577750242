import {
  TrendingUp as TrendingUpIcon,
  FileText as FileTextIcon,
  Airplay as AirplayIcon,
  BookOpen as BookOpenIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon
} from 'react-feather';

const superAdminNav = [
  {
    subheader: 'Learning',
    items: [
      {
        title: 'Content Creation',
        icon: BookOpenIcon,
        href: '/app/content',
        items: [
          {
            title: 'My Content',
            href: '/app/content/content-creators/my-lessons/true'
          }
        ]
      }
    ]
  }
];

export default superAdminNav;
