import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { USER_ROLES } from 'src/constants';

function ContentCreatorGuard({ children }) {
  const { user } = useSelector(state => state.account);

  if (user.user_role === USER_ROLES[2].id) {
    return (
      <Redirect to={`/app/content/content-creators/my-lessons/${user._id}`} />
    );
  }

  return children;
}

ContentCreatorGuard.propTypes = {
  children: PropTypes.any
};

export default ContentCreatorGuard;
