export const SET_SELECTED_TUTOR = '@lessonRequest/set-selected-tutor';
export const SET_SELECTED_LESSON_SCHEDULE =
  '@lessonRequest/set-selected-lesson-schedule';
export const SET_USER_LESSON_REQUEST = '@lessonRequest/set-user-lesson-request';
export const SET_IS_RESCHEDULE = '@lessonRequest/set-is-reschedule';
export const SET_SELECTED_CONFIRMED_LESSON =
  '@lessonRequest/set-selected-confirmed-lesson';

export function setSelectedTutor(tutor) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_TUTOR,
      payload: tutor
    });
  };
}
export function setUserLessonRequest(userLessonRequest) {
  return dispatch => {
    dispatch({
      type: SET_USER_LESSON_REQUEST,
      payload: userLessonRequest
    });
  };
}

export function setSelectedLessonSchedule(lessonSchedule) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_LESSON_SCHEDULE,
      payload: lessonSchedule
    });
  };
}

export function setIsReschedule(isReschedule) {
  return dispatch => {
    dispatch({
      type: SET_IS_RESCHEDULE,
      payload: isReschedule
    });
  };
}

export function setSelectedConfirmedLesson(selectedConfirmedLesson) {
  console.log('setSelectedConfirmedLesson', selectedConfirmedLesson);
  return dispatch => {
    dispatch({
      type: SET_SELECTED_CONFIRMED_LESSON,
      payload: selectedConfirmedLesson
    });
  };
}
