import React from 'react';
import { Box, colors, CircularProgress, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import Lottie from 'react-lottie';
import * as paperPlaneAnimation from 'src/assets/paper-plane-loader.json';
import './splashScreen.css';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  },
  logo: {
    width: 150,
    height: 150,
    maxWidth: '100%'
  }
}));

function SlashScreen() {
  const classes = useStyles();

  const paperPlaneAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: paperPlaneAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    // <div className={classes.root}>
    //   <Box display="flex" justifyContent="center" mb={6}>
    //     <Lottie options={paperPlaneAnimationOptions} width={250} />
    //   </Box>
    //   <CircularProgress />
    // </div>
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <Logo className={classes.logo} />
      </Box>
      <CircularProgress color="secondary" />
    </div>
  );
}

export default SlashScreen;
