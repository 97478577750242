/* eslint-disable no-param-reassign */
import { SET_SUBJECT_OPTIONS } from 'src/actions/subjectActions';
import produce from 'immer';
const initialState = {
  subjectOptions: null
};

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBJECT_OPTIONS: {
      const subjectOptions = action.payload;

      state.subjectOptions = subjectOptions;
    }

    default: {
      return state;
    }
  }
};

export default subjectReducer;
