export const SET_SELECTED_CHILD_OR_DEPENDANT =
  '@lessonRequest/set-selected-child-or-dependant';
export const SET_TUTORS = '@lessonRequest/set-tutors';

export function setSelectedChildOrDependant(selectedChildOrDependant) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_CHILD_OR_DEPENDANT,
      payload: selectedChildOrDependant
    });
  };
}
export function setTutors(value) {
  return dispatch => {
    dispatch({
      type: SET_TUTORS,
      payload: value
    });
  };
}
