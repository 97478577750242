import axios from 'axios';

export const ACTIVE_BLOCK = '@session/active-block';
export const ADD_BLOCK = '@session/add-block';
export const ADD_IMAGE = '@session/add-image';
export const ADD_OPTION_IMAGE = '@session/add-option-image';
export const ADD_IMAGE_ANSWER_OPTION = '@session/add-image-answer-option';
export const ADD_MC_OPTION = '@session/add-multi-choice-option';
export const ADD_EXPRESSION = '@session/add-expression';
export const ADD_SHORT_TEXT_OPTION = '@session/add-short-text-option';
export const DELETE_BLOCK = '@session/delete-block';
export const DELETE_SHORT_TEXT_OPTION = '@session/delete-short-text-block';
export const DUPLICATE_BLOCK = '@session/duplicate-block';
export const DELETE_OPTION = '@session/delete-option';
export const DELETE_EXPRESSION = '@session/delete-expression';
export const DELETE_IMAGE_ANSWER_OPTION = '@session/delete-image-answer-option';
export const EDIT_SHORT_TEXT_OPTION = '@session/edit-correct-option';
export const EDIT_FEEDBACK = '@session/edit-feedback';
export const EDIT_GRAPH_LATEX = '@session/edit-graph-latex';
export const REFRESH_GRAPH = '@session/refresh-graph';
export const EDIT_HINT = '@session/edit-hint';
export const EDIT_AUDIO_SOURCE = '@session/edit-audio-source';
export const EDIT_DROPDOWN_INSTRUCTION = '@session/edit-dropdown-instruction';
export const EDIT_INITIAL_RATING = '@session/edit-initial-rating';
export const EDIT_CORRECT_RATING = '@session/edit-correct-rating';
export const EDIT_MIN_SLIDER_VALUE = '@session/edit-min-slider-value';
export const EDIT_MAX_SLIDER_VALUE = '@session/edit-max-slider-value';
export const EDIT_DEFAULT_SLIDER_VALUE = '@session/edit-default-slider-value';
export const EDIT_CORRECT_SLIDER_VALUE = '@session/edit-correct-slider-value';
export const EDIT_SLIDER_STEPS = '@session/edit-slider-steps';
export const EDIT_NUMBER_OF_STARS = '@session/edit-number-of-rating';
export const EDIT_OPTION_TEXT = '@session/edit-option-text';
export const EDIT_QUESTION_TEXT = '@session/edit-question-text';
export const EDIT_QUESTION_DESCRIPTION = '@session/edit-question-description';
export const GET_BLOCKS = '@session/get-blocks';
export const SELECT_CORRECT_OPTION = '@session/select-correct-option';
export const SELECT_MULTI_CORRECT_OPTION =
  '@session/select-multi-correct-option';
export const SELECT_CORRECT_IMAGE_OPTION =
  '@session/select-correct-image-option';
export const SAVE_NEW_SESSION = '@session/save-new-session';
export const SAVE_UPDATED_SESSION = '@session/save-updated-session';
export const SET_NUMERIC_ONLY = '@session/set-numeric-only';
export const TOGGLE_QUOTED_TEXT = '@session/toggle-quoted-text';
export const SHOW_PICTURE_IMAGE = '@session/show-picture-image';
export const SHOW_LATEX_GRAPH = '@session/show-latex-graph';
export const MATHS_PREVIEW = '@session/maths-preview';
export const MOVE_BLOCK = '@session/move-block';
export const NEW_SESSION = '@session/new-session';
export const RATINGS_ICON = '@session/ratings-icon';
export const REMOVE_IMAGE = '@session/remove-image';
export const REMOVE_OPTION_IMAGE = '@session/remove-option-image';

export function setActiveBlock(block, index) {
  return dispatch => {
    dispatch({
      type: ACTIVE_BLOCK,
      payload: { block, index }
    });
  };
}

export function addBlock(block) {
  return dispatch => {
    dispatch({
      type: ADD_BLOCK,
      payload: block
    });
  };
}

export function addImage(file, blockIndex) {
  return dispatch => {
    dispatch({
      type: ADD_IMAGE,
      payload: { blockIndex, file }
    });
  };
}

export function addOptionImage(file, blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: ADD_OPTION_IMAGE,
      payload: { blockIndex, file, optionIndex }
    });
  };
}

export function addImageAnswerOption(blockIndex) {
  return dispatch => {
    dispatch({
      type: ADD_IMAGE_ANSWER_OPTION,
      payload: blockIndex
    });
  };
}

export function addMultiChoiceOption(blockIndex) {
  return dispatch => {
    dispatch({
      type: ADD_MC_OPTION,
      payload: blockIndex
    });
  };
}

export function addExpression(blockIndex) {
  return dispatch => {
    dispatch({
      type: ADD_EXPRESSION,
      payload: blockIndex
    });
  };
}

export function addShortTextOption(blockIndex) {
  return dispatch => {
    dispatch({
      type: ADD_SHORT_TEXT_OPTION,
      payload: blockIndex
    });
  };
}

export function deleteBlock(blockIndex) {
  return dispatch => {
    dispatch({
      type: DELETE_BLOCK,
      payload: blockIndex
    });
  };
}

export function duplicateBlock(blockIndex, newBlocks) {
  return dispatch => {
    dispatch({
      type: DUPLICATE_BLOCK,
      payload: { blockIndex, newBlocks }
    });
  };
}

export function deleteOption(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: DELETE_OPTION,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function deleteExpression(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: DELETE_EXPRESSION,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function deleteImageAnswerOption(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: DELETE_IMAGE_ANSWER_OPTION,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function deleteShortTextOption(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: DELETE_SHORT_TEXT_OPTION,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function editFeedback(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_FEEDBACK,
      payload: { blockIndex, text }
    });
  };
}

export function editCorrectOption(blockIndex, text, OptionIndex) {
  return dispatch => {
    dispatch({
      type: EDIT_SHORT_TEXT_OPTION,
      payload: { blockIndex, text, OptionIndex }
    });
  };
}

export function editHint(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_HINT,
      payload: { blockIndex, text }
    });
  };
}

export function editAudioSource(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_AUDIO_SOURCE,
      payload: { blockIndex, text }
    });
  };
}

export function editMinSliderValue(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_MIN_SLIDER_VALUE,
      payload: { blockIndex, text }
    });
  };
}

export function editMaxSliderValue(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_MAX_SLIDER_VALUE,
      payload: { blockIndex, text }
    });
  };
}

export function editDefaultSliderValue(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_DEFAULT_SLIDER_VALUE,
      payload: { blockIndex, text }
    });
  };
}

export function editCorrectSliderValue(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_CORRECT_SLIDER_VALUE,
      payload: { blockIndex, text }
    });
  };
}

export function editSliderSteps(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_SLIDER_STEPS,
      payload: { blockIndex, text }
    });
  };
}

export function editDropDownInstruction(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_DROPDOWN_INSTRUCTION,
      payload: { blockIndex, text }
    });
  };
}

export function editOptionText(blockIndex, optionIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_OPTION_TEXT,
      payload: { blockIndex, optionIndex, text }
    });
  };
}

export function editQuestionText(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_QUESTION_TEXT,
      payload: { blockIndex, text }
    });
  };
}

export function editGraphLatex(blockIndex, optionIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_GRAPH_LATEX,
      payload: { blockIndex, optionIndex, text }
    });
  };
}

export function refreshGraph(blockIndex) {
  return dispatch => {
    dispatch({
      type: REFRESH_GRAPH,
      payload: { blockIndex }
    });
  };
}

export function editQuestionDesc(blockIndex, text) {
  return dispatch => {
    dispatch({
      type: EDIT_QUESTION_DESCRIPTION,
      payload: { blockIndex, text }
    });
  };
}

export function editInitialRating(blockIndex, value) {
  return dispatch => {
    dispatch({
      type: EDIT_INITIAL_RATING,
      payload: { blockIndex, value }
    });
  };
}

export function editCorrectRating(blockIndex, value) {
  return dispatch => {
    dispatch({
      type: EDIT_CORRECT_RATING,
      payload: { blockIndex, value }
    });
  };
}

export function editNumberOfStars(blockIndex, value) {
  return dispatch => {
    dispatch({
      type: EDIT_NUMBER_OF_STARS,
      payload: { blockIndex, value }
    });
  };
}

export function getBlocks(session) {
  return dispatch => {
    dispatch({
      type: GET_BLOCKS,
      payload: session
    });
  };
}

export function mathsPreview(blockIndex, blockProperty, optionIndex) {
  return dispatch => {
    dispatch({
      type: MATHS_PREVIEW,
      payload: { blockIndex, blockProperty, optionIndex }
    });
  };
}

export function showPictureImage(blockIndex) {
  return dispatch => {
    dispatch({
      type: SHOW_PICTURE_IMAGE,
      payload: { blockIndex }
    });
  };
}

export function showLatexGraph(blockIndex) {
  return dispatch => {
    dispatch({
      type: SHOW_LATEX_GRAPH,
      payload: { blockIndex }
    });
  };
}

export function toggleQuotedText(blockIndex) {
  return dispatch => {
    dispatch({
      type: TOGGLE_QUOTED_TEXT,
      payload: { blockIndex }
    });
  };
}

export function moveBlock(fromIndex, toIndex) {
  return dispatch => {
    dispatch({
      type: MOVE_BLOCK,
      payload: { fromIndex, toIndex }
    });
  };
}

export function newSession() {
  return dispatch => {
    dispatch({
      type: NEW_SESSION
    });
  };
}

export function removeImage(blockIndex) {
  return dispatch => {
    dispatch({
      type: REMOVE_IMAGE,
      payload: { blockIndex }
    });
  };
}

export function removeOptionImage(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: REMOVE_OPTION_IMAGE,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function saveNewSession(session) {
  return dispatch => {
    dispatch({
      type: SAVE_NEW_SESSION,
      payload: session
    });
  };
}

export function saveUpdatedSession(session) {
  return dispatch => {
    dispatch({
      type: SAVE_UPDATED_SESSION,
      payload: session
    });
  };
}

export function selectCorrectOption(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: SELECT_CORRECT_OPTION,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function selectCorrectMultiOption(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: SELECT_MULTI_CORRECT_OPTION,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function selectCorrectImageOption(blockIndex, optionIndex) {
  return dispatch => {
    dispatch({
      type: SELECT_CORRECT_IMAGE_OPTION,
      payload: { blockIndex, optionIndex }
    });
  };
}

export function setRatingsIcon(blockIndex, icon) {
  return dispatch => {
    dispatch({
      type: RATINGS_ICON,
      payload: { blockIndex, icon }
    });
  };
}

export function setNumericOnly(blockIndex) {
  return dispatch => {
    dispatch({
      type: SET_NUMERIC_ONLY,
      payload: { blockIndex }
    });
  };
}
